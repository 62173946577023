<template>
  <div>
    <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0">
        <!-- Brand logo-->
        <b-link class="brand-logo">
          <img class="logo" :src="logoImage" height="50" alt="" />
          <!-- <h2 class="brand-text text-primary ml-1">
          Smile and Learn
        </h2> -->
        </b-link>
        <!-- /Brand logo-->

        <!-- Left Text-->
        <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
          <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
            <b-img fluid :src="imgUrl" :alt="$t('auth.welcome')" />
          </div>
        </b-col>
        <!-- /Left Text-->

        <!-- Login-->
        <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
          <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
            <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
              {{ $t("auth.welcome") }}
            </b-card-title>
            <b-card-text class="mb-2">
              {{ $t("auth.welcome_description") }}
            </b-card-text>

            <b-alert variant="danger" show v-if="this.error_message">
              <div class="alert-body font-small-2">
                <p v-html="this.error_message"></p>
              </div>
            </b-alert>
            <!-- form -->
            <validation-observer ref="loginForm" #default="{ invalid }">
              <b-form class="auth-login-form mt-2" @submit.prevent="login">
                <!-- email -->
                <b-form-group label="Email" label-for="login-email">
                  <validation-provider #default="{ errors }" name="Email" vid="email" rules="required" :custom-messages="{ required: $t('validators.required') }">
                    <b-form-input id="login-email" v-model="userEmail" :state="errors.length > 0 ? false : null"
                      name="login-email" placeholder="juan@example.com" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">{{ $t("auth.password") }}</label>
                  </div>
                  <validation-provider #default="{ errors }" name="Password" vid="password" rules="required" :custom-messages="{ required: $t('validators.required') }">
                    <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                      <b-form-input id="login-password" v-model="password" :state="errors.length > 0 ? false : null"
                        class="form-control-merge" :type="passwordFieldType" name="login-password"
                        :placeholder="$t('auth.password')" />
                      <b-input-group-append is-text>
                        <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility" />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              <!-- Password recover -->
                <b-form-group label-for="forgot-password">
                  <template v-slot:label>
                    <b-link v-if="!isEducamadrid()" :to="{ name: 'auth-forgot-password' }">
                    {{ $t("auth.forgot_password") }}</b-link>
                  </template>
                </b-form-group>

                <!-- submit buttons -->
                <b-button type="submit" variant="primary" block :disabled="invalid || loading">
                  <div v-if="!loading">
                    {{ $t("auth.sign_in") }}
                  </div>
                  <div v-else>
                    <b-spinner small type="grow" />
                  </div>
                </b-button>
              </b-form>
            </validation-observer>

            <!-- divider -->
            <div v-if="!isEducamadrid()">
              <div class="divider my-2">
                <div class="divider-text">
                  {{ $t("auth.or") }}
                </div>
              </div>
                  <!-- social buttons -->
                  <div class="auth-footer-btn d-flex justify-content-center">
                    <b-button variant="light" :href="socialURLGoogle">
                      <img src="@/assets/images/pages/login/google-color.svg" height="26" alt="Google icon" />
                    </b-button>
                  <b-button variant="light" :href="socialURLApple">
                    <img src="@/assets/images/pages/login/apple.svg" height="21" alt="Apple icon" />
                  </b-button>
                <b-button variant="light" :href="socialURLMicrosoft">
                  <img src="@/assets/images/pages/login/microsoft.svg" height="17" alt="Microsoft icon" />
                </b-button> 
              </div>
            </div>
          </b-col>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { handleLogin, manteinance } from '@/api/routes'


import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BAlert,
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BSpinner,
  VBTooltip,
  BRow,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { isEducamadridFunction, logoImageFunction } from '@/main';

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BAlert,
    BButton,
    BCardText,
    BCardTitle,
    BCol,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BImg,
    BInputGroup,
    BInputGroupAppend,
    BLink,
    BSpinner,
    ValidationObserver,
    ValidationProvider,
    BRow,

    VuexyLogo,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      //sideImg: require('@/assets/images/pages/login-v2.svg'),
      sideImg: require("@/assets/images/pages/login/intro.png"),

      // validation rules
      required,
      email,

      error_message: "",

      loading: false,

      socialLoginHost: process.env.VUE_APP_SOCIAL_AUTH_HOST,
      socialLoginGoogle: '/sso-login/google',
      socialLoginApple: '/sso-login/apple',
      socialLoginMicrosoft: '/sso-login/azure',
      socialURLGoogle: '',
      socialURLApple: '',
      socialURLMicrosoft: '',

    };
  },
  mounted() {
    this.socialURLGoogle = this.socialLoginHost + this.socialLoginGoogle;
    this.socialURLApple = this.socialLoginHost + this.socialLoginApple;
    this.socialURLMicrosoft = this.socialLoginHost + this.socialLoginMicrosoft;
    this.checkURLToast();
    this.obtenerIdiomaNavegador();
    this.checkMaintenance();
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login/intro.png");
        return this.sideImg;
      }
      return this.sideImg;
    },
    logoImage() {
      logoImageFunction();
    }
  },
  methods: {
    checkURLToast(){
      var errors = this.$route.query
      if ( Object.keys(errors).length !== 0 ) {
        this.makeToast(this.$route.query.type, this.$t('learning_path.'+ (this.$route.query.title  ?? 'error')), this.$t(this.$route.query.error));   
      }
    },
    checkMaintenance(){
      console.log("checkMaintenance");
      this.$http.get(manteinance);
    },
    obtenerIdiomaNavegador() {
      // Obtener el idioma del navegador del cliente
      let idiomaNavegador = navigator.language || navigator.userLanguage;
      idiomaNavegador = idiomaNavegador.split("-")[0];
      if (Object.keys(this.$i18n.messages).includes(idiomaNavegador)) {
        this.$i18n.locale = idiomaNavegador;
      } else {
        // Si el idioma del navegador no está disponible, devuelve un idioma predeterminado
        this.$i18n.locale = 'en';
      }
    },
    makeToast(variant = null, tit = '', msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    login() {
      this.loading = true
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          if (isEducamadridFunction() && !this.userEmail.includes('@')) {
            this.userEmail += '@educa.madrid.org';
          }
          this.$http.post(handleLogin, {
            email: this.userEmail,
            password: this.password,
          }).then((response) => {
              if (response.data.status == 200) {
                const { userData } = response.data;
                useJwt.setToken(response.data.accessToken);
                useJwt.setRefreshToken(response.data.refreshToken);
                this.$store.commit("user/setUser", userData);
                this.$store.commit("general/setLangs", response.data.langs);

                // Define los permisos de ese usuario logueado
                this.$ability.update(userData.ability);

                //SET LOCAL LANG
                this.$i18n.locale = userData.lang;

                if (userData.privacy_accepted === null) {
                  // If not, redirect to '/termspage'
                  this.$router.replace('termspage').then(() => {
                    setTimeout(() => {
                      this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                          title: this.$t("dash.greeting", {
                            name: userData.fullName || userData.username,
                          }),
                          icon: "CoffeeIcon",
                          variant: "success",
                          text: this.$t("auth.successfull_log_in_message", {
                            user_role: userData.role,
                          }),
                        },
                      });
                    }, 800); // Retrasa la ejecución del toast en 8 segundos
                  });
                } else {
                  // If 'guardarTimestamp' is 'true', redirect to main-home
                  // console.log("----------privacy Accepted replace to home")
                  this.$router.replace({ name: 'main-home' });
                }
              } else {
                this.loading = false
                // console.log(response.data);
                if (response.data.url) this.error_message = this.$t(response.data.msg_code, {url : response.data.url});
                else response.data.msg_code ? this.error_message = this.$t(response.data.msg_code) : this.error_message = response.data.message || response.data.msg;
                this.$refs.loginForm.setErrors(response.data.message || response.data.msg);
              }
              // console.log("error", response)
            }).catch((error) => {
              this.loading = false
              // console.log("-----error", error)
              this.makeToast('danger', this.$t('Error'), this.$t('error_retry_later'));
              // console.log(error);
              //this.$refs.loginForm.setErrors(error.response.data.error);
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

@media screen and (orientation:landscape) {
  .logo {
    margin-bottom: 20px;
    height: 35px; 
  }
}
</style>
